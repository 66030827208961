import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {configureStore} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react';

import { persistStore } from 'redux-persist'


const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App' ));
const store = configureStore()
const persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Suspense fallback={<div className="loading"/>}>
                <App/>
            </Suspense>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
