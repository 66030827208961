import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from "redux-saga";
import {composeWithDevTools} from 'redux-devtools-extension';
import reducers from './reducers';
import sagas from "./sagas";
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import rootReducer from './reducers'
import {createLogger} from 'redux-logger';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk];
// const middlewares = [sagaMiddleware, thunk, createLogger()];
// if (__DEV__) {
//     middlewares.push(createLogger());
// }

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authUser']
}

const persistedReducer = persistReducer(persistConfig, reducers)

export function configureStore() {

    const store = createStore(
        persistedReducer,
        composeWithDevTools(
            applyMiddleware(...middlewares)
        )
    );

    sagaMiddleware.run(sagas);

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers');
            store.replaceReducer(nextRootReducer);
        });
    }
    //store.subscribe(()=>console.log(store.getState()))


    return store;
}

